









import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'

import { QUANTITY_INPUT_COMPONENT_CONFIG_MAP, QUANTITY_INPUT_COMPONENT_KEY } from './QuantityInput.config'
import { StructureConfigurable } from '../../../../support/mixins'
import { QuantityPicker } from '../../molecules/QuantityPicker'
import { ToastMixin } from '../../mixins'

@Component<QuantityInput>({
  name: 'QuantityInput',
  components: { QuantityPicker },
  created () {
    this.config = this.getComponentConfig(
      QUANTITY_INPUT_COMPONENT_KEY,
      QUANTITY_INPUT_COMPONENT_CONFIG_MAP
    )
  }
})
export class QuantityInput extends Mixins(ToastMixin, StructureConfigurable) {
  @PropSync('quantity', { type: Number, required: true })
  public _quantity!: number

  @Prop({ type: Boolean, required: false, default: true })
  public readonly isAvailable!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isLoading!: boolean

  @Prop({ type: Number, required: false })
  public readonly max?: number

  @Prop({ type: Number, required: false })
  public readonly step?: number

  public get counter (): number {
    return this.step ?? this.defaultCounter
  }

  public get defaultCounter (): number {
    return this.getConfigProperty<number>('COUNTER_VALUE')
  }

  public get maxQty (): number {
    return this.max ?? this.getConfigProperty<number>('MAX_VALUE')
  }

  public get minQty (): number {
    return this.getConfigProperty<number>('MIN_VALUE')
  }

  public onUpdate (quantity: number): void {
    if (quantity % this.counter !== 0) {
      this._quantity = Math.round(quantity / this.counter) * this.counter

      this.showToast(this.$t('front.checkout.molecules.QuantityPicker.counterWarning', { counter: this.counter }).toString(), 'info')
    } else {
      this._quantity = quantity
    }
  }
}

export default QuantityInput
