export const QUANTITY_INPUT_COMPONENT_KEY = 'QuantityInputComponent'

export interface QuantityInputComponentConfig {
  MAX_VALUE: number
  COUNTER_VALUE: number
  MIN_VALUE: number
}

export const QUANTITY_INPUT_COMPONENT_CONFIG_MAP: QuantityInputComponentConfig = {
  MAX_VALUE: 150,
  COUNTER_VALUE: 1,
  MIN_VALUE: 0
}
