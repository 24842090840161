












































































import { Component, Mixins } from 'vue-property-decorator'

import { AllowedAttributes } from '../../../../../contexts'
import { Inject } from '../../../../../support'

import { IProductsRepository, ProductsRepositoryType } from '../../../contracts/repositories'

import ProductCardMixin from '../ProductCard.mixin'
import Price from '../../../../shared/molecules/Price/Price.vue'
import { QuantityInput } from '../../../../shared/organisms/QuantityInput'
import BaseCompany from '../../../../shared/mixins/base-company.mixin'

import { ImageFit } from '../../../../../dsl/atoms/Image/Image.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ProductCardBuyable>({
  name: 'ProductCardBuyable',
  components: { Price, QuantityInput },
  beforeMount () {
    this.setCurrentQuantityFromCart()
  }
})
export class ProductCardBuyable extends Mixins<BaseCompany & ProductCardMixin>(BaseCompany, ProductCardMixin) {
  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  public addToCartBtnLoading: boolean = false
  public currentQuantity: number = 0

  public get disabled (): boolean {
    return !this.hasSellableQuantity || this.quantity === this.cartQuantity
  }

  public get quantity (): number {
    if (this.currentQuantity < 0) {
      return this.cartQuantity
    }

    return this.currentQuantity
  }

  public set quantity (value: number) {
    this.currentQuantity = value
  }

  public get cartQuantity (): number {
    return this.quantityInCart(this.activeVariant?.sku)
  }

  public get addToCartLabel (): string {
    if (!this.hasSellableQuantity) {
      return this.$t('front.products._common.unavailable').toString()
    }

    if (this.isInCart) {
      return this.$t('front.products._common.updateInCart').toString()
    }

    return this.$t('front.products._common.addToCart').toString()
  }

  /**
   * Checks whether all variants of the current product are unavailable
   */
  public get allVariantsUnavailable (): boolean {
    if (!this.resolvedProduct) {
      return false
    }

    const temp = []
    for (const variant of Object.values(this.resolvedProduct.variants)) {
      if (variant.isAvailable) {
        temp.push(variant)
      }
    }

    return temp.length === 0
  }

  private get brand (): string | undefined {
    if (typeof this.getAttribute<string>(AllowedAttributes.Brand) === 'undefined') {
      return
    }

    return this.getAttribute<string>(AllowedAttributes.Brand)
  }

  public get isInCart (): boolean {
    return !!this.quantityInCart(this.activeVariant?.sku)
  }

  public get packageAmount (): number {
    return this.getAttribute<number>(AllowedAttributes.PackageAmount) ?? 0
  }

  public get imageFit (): ImageFit {
    return ImageFit.Contain
  }

  public async handleAddToCart (): Promise<void> {
    if (!this.activeVariant) {
      return
    }

    if (this.cartQuantity > this.quantity) {
      if (this.quantity === 0) {
        await this.removeFromCart(this.activeVariant.sku)
      } else {
        await this.updateCartItem(this.activeVariant.sku, this.quantity)
      }
    } else {
      await this.onAddToCart(false, false, this.quantity)
    }
  }

  protected setCurrentQuantityFromCart (): void {
    this.currentQuantity = this.cartQuantity
  }
}

export default ProductCardBuyable
